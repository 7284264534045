import { DateTime } from 'luxon'
import { download } from 'shared/helpers/downloads/download'

import { ReportFileExportFormat } from '../../../services/types/Report'

export const exportFile = (
  fileName: string,
  type: ReportFileExportFormat,
  data: Parameters<typeof download>[0],
  startDate: DateTime = DateTime.now(),
  endDate: DateTime = DateTime.now()
) => {
  const name = `${fileName}_${startDate.toFormat('yyyy-MM-dd')}-${endDate.toFormat('yyyy-MM-dd')}.${type}`

  download(data, name)
}
