import React, { useEffect, useRef, useState } from 'react'
import { NavigateOptions } from 'react-router'
import { useFirstRender } from '@coddess-development/coddess-ui'
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid'
import useStorage from 'business/shared/hooks/useStorage'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { Paginated, PaginationMeta, SortMeta } from 'services/types'
import { QueryStringParams } from 'shared/helpers/getQueryString'
import {
  parseSearchQueryParams,
  SearchQueryParams,
  toSearchParamsQueryString,
  useSearchQuery
} from 'shared/hooks/use-search-query/useSearchQuery'
import { SWRFetcherKeyTuple, SWRSuspenseConfiguration } from 'shared/types'
import useSWR, { useSWRConfig } from 'swr'

export type UsePaginatedFormValues<
  T extends () => { filters: QueryStringParams }
> = Record<keyof ReturnType<T>['filters'], any>

export type UsePaginatedContext<
  T extends (...args: any[]) => any,
  K extends string = 'ctx'
> = {
  [key in K]: ReturnType<T>
}

export type UsePaginatedStorageType = 'url' | 'memory'

export type UsePaginatedOptions<
  T,
  P extends QueryStringParams = QueryStringParams,
  E extends object = {}
> = Omit<SWRSuspenseConfiguration<Paginated<T, E>>, 'suspense'> & {
  /**
   * Whether filters are saved between page navigations and user sessions.
   *
   * @default false
   */
  saveFilters?: boolean
  params?: P
  defaultSort?: SortMeta<T>
  /**
   * The type of storage to use for the parameters
   *
   * @default 'url'
   */
  storage?: UsePaginatedStorageType
  /**
   * A unique identifier key to use for the parameters inside the storage. This is meant to be used in combination
   * with `storage: 'url'` when multiple `usePaginated` calls exist on a single screen, in order to
   * prevent conflicts.
   */
  storageKey?: string
  /**
   * Whether the resource is immutable from outside sources. This will enable almost all caching mechanisms.
   *
   * @default false
   */
  immutable?: boolean
  /**
   * Whether to show the perPage options in the pagination component.
   */
  perPageOptions?: boolean
}

type UseQueryParamsStorageResult<P extends QueryStringParams> = readonly [
  params: SearchQueryParams<P>,
  setParams: (params: Partial<P>, options?: NavigateOptions | undefined) => void
]

type ParamsWithDefaults<P extends object> = P & typeof DefaultParams

const DefaultParams = {
  page: 1,
  perPage: 15,
  sortBy: '',
  sortOrder: '' as GridSortDirection,
  // We are using this as a cache buster, to force refetch the data when needed.
  $t: null as number | null
}

/**
 * A common abstraction for paginated resources, with or without extra filters.
 */
export function usePaginated<
  T,
  P extends QueryStringParams = {},
  UrlNullable extends boolean = false,
  E extends object = {}
>(
  url: UrlNullable extends false ? string : string | null,
  {
    params = {} as P,
    saveFilters = false,
    storage = 'url',
    storageKey,
    defaultSort,
    immutable = false,
    perPageOptions,
    ...config
  }: UsePaginatedOptions<T, P, E> = {}
) {
  type PaginatedResource = UrlNullable extends false
    ? Paginated<T, E>
    : Paginated<T, E> | undefined

  const firstRender = useFirstRender()
  const isMounted = useRef(true)
  const swrConfig = useSWRConfig()

  useEffect(() => () => {
    isMounted.current = false
  })

  const initialQueryParams = formatSavedParams({
    ...DefaultParams,
    ...defaultSort,
    ...params
  })

  const [savedQueryParams, setSavedQueryParams] = useStorage(
    saveFilters ? `filters@${url}` : null,
    initialQueryParams
  )

  // We debounce changes to the actual query params to prevent spamming requests
  // to the server in some scenarios, essentially batching everything into a single request.
  const [debouncedQueryParams, setDebouncedQueryParams] = useQueryParamsStorage(
    // When the url's search query is not empty, we want to use it as a source of information
    // for what filters are selected, above all. This means that either the user has already
    // touched some of the filters, or they are visiting the page through a link, which is
    // expected to show exactly what the sharing user wanted it to show based on their
    // selected filters, represented by the url's search query information.
    document.location.search ? initialQueryParams : savedQueryParams,
    storage,
    storageKey
  )

  const [queryParams, setQueryParams] = useQueryParamsStorage(
    debouncedQueryParams,
    'memory',
    ''
  )

  useEffect(() => {
    // This check is necessary in order to avoid continuous re-rendering caused by useQueryParamsStorage
    if (isEqual(debouncedQueryParams, queryParams)) {
      return () => {}
    }

    const handler = setTimeout(() => {
      setQueryParams(debouncedQueryParams as ParamsWithDefaults<P>)
    }, 650)

    return () => {
      clearTimeout(handler)
    }
  }, [debouncedQueryParams, queryParams, setQueryParams])

  const instantlySetQueryParams: typeof setDebouncedQueryParams = (...args) => {
    if (saveFilters) {
      setSavedQueryParams(
        omit(
          formatSavedParams({
            ...filters,
            ...args[0]
          }),
          Object.keys(DefaultParams)
        ) as ParamsWithDefaults<P>
      )
    }

    setDebouncedQueryParams(...args)
    setQueryParams(...args)
  }

  const syncQueryWithMeta = (response: PaginatedResource) => {
    if (firstRender || !response) {
      return
    }

    // We may have a successsful response, after the component is unmounted. This may lead to a situation
    // where user has navigated away from the page, but the successful response updates the url to that of
    // the previous page and thus sends the user back to this previous page.
    if (!isMounted.current) {
      return
    }

    const { meta } = response

    if (
      Number(queryParams.page) !== Number(meta.currentPage) ||
      Number(queryParams.perPage) !== Number(meta.perPage)
    ) {
      instantlySetQueryParams({
        page: meta.currentPage,
        perPage: meta.perPage
      } as ParamsWithDefaults<P>)
    }
  }

  const key = (url ? [url, queryParams] : null) as UrlNullable extends false
    ? SWRFetcherKeyTuple
    : SWRFetcherKeyTuple | null

  const result = useSWR<
    PaginatedResource,
    typeof key,
    SWRSuspenseConfiguration
  >(key, {
    onSuccess: syncQueryWithMeta,
    suspense: true,
    // Immutable use of the hook means that we do not want to revalidate automatically.
    // However, when we are not in immutable state, we want to fall back to the global
    // configuration, which will happen automatically when we pass `undefined` here.
    revalidateIfStale: immutable ? false : swrConfig.revalidateIfStale,
    revalidateOnFocus: immutable ? false : swrConfig.revalidateOnFocus,
    revalidateOnMount: immutable ? false : swrConfig.revalidateOnMount,
    ...config
  })

  const filters = omit(
    debouncedQueryParams,
    Object.keys(DefaultParams)
  ) as SearchQueryParams<Omit<P, keyof typeof DefaultParams>>

  const internalParams = omit(
    debouncedQueryParams,
    Object.keys(filters)
  ) as SearchQueryParams<typeof DefaultParams>

  const response = result.data

  return {
    ...result,
    filters,
    key,
    url,
    setDebouncedQueryParams,
    setQueryParams: instantlySetQueryParams,
    meta: result.data?.meta as UrlNullable extends false
      ? PaginationMeta<E>
      : PaginationMeta<E> | undefined,
    data: result.data?.data as UrlNullable extends false
      ? T[]
      : T[] | undefined,
    /**
     * Use for search operations where many requests might be issued in rapid succession,
     * such as user typing in an input field.
     */
    search: (fltr: Partial<P> = params) => {
      setDebouncedQueryParams(
        {
          ...fltr,
          // We never know if we'll have more than one page after filtering,
          // and may leave user hanging on a non-existent page, if we don't reset the page.
          page: 1
        } as ParamsWithDefaults<P>,
        { replace: true }
      )
    },
    /**
     * Use for filter operations where multiple requests are not expected to be issued
     * in a rapid succession, such as option or toggle.
     */
    filter: (fltr: Partial<P> | Partial<SearchQueryParams<P>> = params) => {
      instantlySetQueryParams(
        {
          ...fltr,
          // We never know if we'll have more than one page after filtering,
          // and may leave user hanging on a non-existent page, if we don't reset the page.
          page: 1,
          $t: Date.now()
        } as ParamsWithDefaults<P>,
        { replace: true }
      )
    },
    sort: (sort?: SortMeta) => {
      instantlySetQueryParams(
        {
          sortBy: sort?.sortBy || '',
          sortOrder: sort?.sortOrder || ''
        } as ParamsWithDefaults<P>,
        { replace: true }
      )
    },
    sortModel: internalParams.sortBy
      ? ([
          {
            field: internalParams.sortBy,
            sort: internalParams.sortOrder as GridSortDirection
          }
        ] satisfies GridSortModel)
      : [],
    pagination: {
      ...response?.meta,
      page: response?.meta.currentPage || Number(queryParams.page),
      perPage: response?.meta.perPage || Number(queryParams.perPage),
      onPageChange: (_: React.ChangeEvent<any> | null, page: number) => {
        instantlySetQueryParams({ page } as ParamsWithDefaults<P>)
      },
      onPerPageChange: perPageOptions
        ? (perPage: number) => {
            instantlySetQueryParams({ perPage } as ParamsWithDefaults<P>)
          }
        : undefined
    }
  }
}

function useQueryParamsStorage<P extends QueryStringParams>(
  args: P,
  storage: UsePaginatedStorageType,
  key: string | undefined
): UseQueryParamsStorageResult<P> {
  const params = formatSavedParams(args)

  const isMemoryStorage = storage === 'memory'

  const [memoryParams, setMemoryParams] = useState<SearchQueryParams<P>>(
    isMemoryStorage ? params : ({} as P)
  )

  const urlControls = useSearchQuery<P>(
    !isMemoryStorage ? params : ({} as P),
    key
  )
  const memoryControls: UseQueryParamsStorageResult<P> = [
    memoryParams,
    // We want to allow users to provide a partial set of params, so we need to merge them with the existing ones.
    params =>
      setMemoryParams(
        prev =>
          parseSearchQueryParams(
            toSearchParamsQueryString({ ...prev, ...params })
          ) as P
      )
  ]

  return isMemoryStorage ? memoryControls : urlControls
}

function formatSavedParams<T extends object>(params: T) {
  return parseSearchQueryParams(toSearchParamsQueryString(params)) as T
}
