import { toast } from 'react-toastify'
import {
  ConfigurationGroup,
  ConfigurationOptions,
  ResponseWithMessage
} from '@packages/types'
import axios, { AxiosResponse } from 'axios'
import {
  ManagementPermissions,
  ManagerListResponse,
  ManagerListRow
} from 'business/configuration/managersList/types'
import { usePaginated } from 'services/shared/usePaginated'
import User from 'services/types/User'
import { SWRFetcherKey, SWRSuspenseConfiguration } from 'shared/types'
import useSWRImmutable from 'swr/immutable'
import useSWRMutation from 'swr/mutation'

import {
  ManagerCreateRequestParams,
  ManagerUpdateRequestParams
} from './types/configuration.types'
import Permission from './types/Permission'
import client from './client'

export const useManagersList = (type?: 'suspended') => {
  const params = { search: '', type }
  const url = '/management-users'

  const { key, ...result } = usePaginated<ManagerListRow, typeof params>(url, {
    params,
    immutable: true
  })

  const { trigger: create } = useSWRMutation<
    AxiosResponse<ManagerListResponse>,
    any,
    typeof key,
    ManagerCreateRequestParams
  >(key, async (_, { arg }) => {
    const response = await client.post<ManagerListResponse>(url, arg)

    toast(response.data.message, { type: 'success' })

    return response
  })

  const { trigger: update } = useSWRMutation<
    AxiosResponse<ManagerListResponse>,
    any,
    typeof key,
    ManagerUpdateRequestParams
  >(key, async (_, { arg }) => {
    const { id, ...user } = arg

    const response = await client.put<ManagerListResponse>(`${url}/${id}`, user)

    toast(response.data.message, { type: 'success' })

    return response
  })

  const { trigger: suspend } = useSWRMutation<
    AxiosResponse<User>,
    any,
    typeof key,
    { id: number | string; reason?: string }
  >(key, async (_, { arg }) => {
    const { id, reason } = arg

    const response = await client.put<User>(`/users/suspend/${id}`, { reason })

    toast('Manager suspended', { type: 'success' })

    return response
  })

  const { trigger: unsuspend } = useSWRMutation<
    AxiosResponse<User>,
    any,
    typeof key,
    number | string
  >(key, async (_, { arg }) => {
    const response = await client.put<User>(`/users/unsuspend/${arg}`, {})

    toast('Manager suspension revoked', { type: 'success' })

    return response
  })

  return {
    ...result,
    data: result.data!.map(({ userProfile, ...user }) => ({
      ...userProfile,
      ...user
    })),
    create: (
      params: ManagerCreateRequestParams,
      options?: Parameters<typeof create>[1]
    ) => create(params, options) as ReturnType<typeof create>,
    update: (
      params: ManagerUpdateRequestParams,
      options?: Parameters<typeof update>[1]
    ) => update(params, options) as ReturnType<typeof update>,
    suspend: (id: number | string, reason?: string) => suspend({ id, reason }),
    unsuspend: (id: number | string) => unsuspend(id)
  }
}

export const useSuspendedManagersList = () => {
  return useManagersList('suspended')
}

export const useManagementPermissions = () => {
  return useSWRImmutable<
    ManagementPermissions,
    string,
    SWRSuspenseConfiguration
  >(`/affiliate-manager-permissions`, {
    suspense: true,
    revalidateOnMount: false
  }).data
}

export const useAffiliateVisibilityPermissions = () => {
  return useSWRImmutable<Permission[], string, SWRSuspenseConfiguration>(
    `/affiliate-visibility-permissions`,
    { suspense: true, revalidateOnMount: false }
  ).data
}

export function useAffiliateViewManagementConfiguration() {
  const key: SWRFetcherKey = '/affiliate-view-management-permissions'

  const result = useSWRImmutable<
    Permission[],
    typeof key,
    SWRSuspenseConfiguration
  >(key, { suspense: true, revalidateOnMount: false })

  const { trigger: update, isMutating: isUpdating } = useSWRMutation<
    void,
    any,
    typeof key,
    string[]
  >(key, async (_, { arg }) => {
    await client.post('/assign-affiliate-view-management-permissions', {
      permissions: arg
    })

    toast('Permissions updated successfuly.', { type: 'success' })
  })

  return {
    ...result,
    isUpdating,
    data: result.data,
    update: (slugs: string[], config?: Parameters<typeof update>[1]) => {
      return update(slugs, config)
    }
  }
}

export function useConfiguration<T extends ConfigurationGroup>(group: T) {
  const url = `/${group.toLowerCase()}-configuration`
  const key = url

  const result = useSWRImmutable<
    ConfigurationOptions<T>,
    string,
    SWRSuspenseConfiguration
  >(key, {
    suspense: true,
    revalidateOnMount: false
  })

  const update = useSWRMutation<
    AxiosResponse<ResponseWithMessage<void>>,
    any,
    typeof key,
    ConfigurationOptions<T>
  >(key, async (_, { arg }) => {
    const response = await client.put<ResponseWithMessage<void>>(
      url,
      axios.toFormData(arg),
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )

    toast(response.data.message, { type: 'success' })

    return response
  })

  return {
    ...result,
    update
  }
}
