/**
 *
 * In this files we have representations of common validation rules, which
 * should be used in the whole application in order to enforce consistency.
 *
 */

import {
  addressRegEx,
  cityRegEx,
  cleanSpacingRegEx,
  extendedWebsiteUrlRegEx,
  nameRegEx,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  phoneNumberRegEx,
  zipCodeRegEx
} from '@packages/constants'
import { SignupFieldConfigurationOption } from '@packages/types'
import isEmailValidator from 'validator/lib/isEmail'
import {
  ISchema,
  isSchema,
  object,
  ObjectSchema,
  ref,
  string,
  StringSchema,
  ValidationError
} from 'yup'

export const limitedLengthString = (
  min: number = 2,
  max: number = 50,
  schemaBase: StringSchema | undefined = undefined,
  trimInitialAndDoubleSpaces: boolean = false
) => {
  let schema = string()

  if (schemaBase) {
    schema = schemaBase
  }

  if (trimInitialAndDoubleSpaces) {
    schema = schema.matches(
      cleanSpacingRegEx,
      'No leading, trailing or consecutive spaces allowed'
    )
  }

  return schema.min(min, 'Value is too short').max(max, 'The value is too long')
}

export const trimmedLimitedLengthString = (
  schemaBase: StringSchema | undefined = undefined,
  min: number = 2,
  max: number = 50
) => limitedLengthString(min, max, schemaBase, true)

const nameSchema = string().matches(nameRegEx, 'Enter a valid name')
export const name = trimmedLimitedLengthString(nameSchema)

export const email = string()
  .test('is-valid-email', 'Enter a valid email address', value =>
    value
      ? isEmailValidator(value)
      : new ValidationError('Enter a valid email address')
  )
  .min(5, 'Value is too short')
  .max(50, 'The value is too long')

export const password = string()
  .min(PASSWORD_MIN_LENGTH, 'Value is too short')
  .max(PASSWORD_MAX_LENGTH, 'The entered password is too long')

const citySchema = string().matches(cityRegEx, 'Enter a valid city')
export const city = trimmedLimitedLengthString(citySchema, 2, 60)

export const addressSchema = string().matches(
  addressRegEx,
  'Enter a valid address'
)
export const address = trimmedLimitedLengthString(addressSchema, 5, 100)

const zipCodeSchema = string().matches(zipCodeRegEx, 'Enter a valid zip code')
export const zipCode = trimmedLimitedLengthString(zipCodeSchema, 2, 20)

export const phoneNumber = string()
  .matches(phoneNumberRegEx, {
    message: 'Enter a valid phone number (digits only)'
  })
  .min(6, 'Phone number is too short')
  .max(15, 'Phone number is too long')
/**
 * Rule is intended to be used in combination with the regular `password` validation rule.
 */
export const repeatPassword = string().oneOf(
  [ref('password'), undefined],
  'Passwords must match'
)

export const website = string().url('Enter a valid website URL')

/**
 * Yup url validation regex
 * @see {@link https://github.com/jquense/yup/blob/master/src/string.ts}
 */
const rUrl =
  // eslint-disable-next-line
  /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\{\}]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\{\}]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\{\}]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\{\}]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=\{\}]|:|@)|\/|\?)*)?$/i

export const websiteWithPlaceholder = string().matches(rUrl, {
  name: 'url',
  message: 'Enter a valid website URL',
  excludeEmptyString: true
})

export const extendedWebsiteWithPlaceholder = string().matches(
  extendedWebsiteUrlRegEx,
  {
    name: 'website',
    message: 'Enter a valid website URL',
    excludeEmptyString: true
  }
)

export const maybe = <S extends ObjectSchema<any> | StringSchema<any>>(
  schema: S,
  error: string,
  config: SignupFieldConfigurationOption = 'required'
) => {
  if (config === 'required') {
    return schema.required(error).typeError(error)
  }

  return schema.optional().notRequired().typeError(error)
}

export function toSchema(rules: Record<string, any>) {
  return object().shape(
    Object.entries(rules).reduce(
      (schema, [key, rule]) => {
        if (isSchema(rule)) {
          schema[key] = rule
        } else {
          schema[key] = toSchema(rule)
        }

        return schema
      },
      {} as Record<string, ISchema<any>>
    )
  )
}
