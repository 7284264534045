import { useConfiguration } from 'services/configuration.service'
import {
  address,
  city,
  email,
  extendedWebsiteWithPlaceholder,
  maybe,
  name,
  password,
  phoneNumber,
  toSchema,
  trimmedLimitedLengthString,
  zipCode
} from 'shared/validations'
import { boolean, number, object, ref, string } from 'yup'

export const useAffiliateSelfUpdateSchema = () => {
  const fields = useConfiguration('signup').data.fields || {}

  const definitions = {
    email: email.required('Enter a valid email address'),
    password: password.test(
      'conditional-required',
      'Password is required',
      // eslint-disable-next-line func-names
      function (value) {
        const { repeatPassword } = this.parent
        return repeatPassword ? !!value : true
      }
    ),
    repeatPassword: password.when('password', {
      is: (password: string | undefined) => !!password,
      then: schema =>
        schema
          .required('Repeat password is required')
          .oneOf([ref('password')], 'Passwords must match'),
      otherwise: schema => schema.notRequired()
    }),
    name: maybe(name, 'Enter a valid name', fields.name),
    enableTwoFactorAuth: boolean().optional(),
    userProfile: {
      phoneNumber: maybe(
        phoneNumber,
        'Enter a valid phone number',
        fields.phone
      ),
      skype: maybe(
        trimmedLimitedLengthString(),
        'Enter a valid Skype ID',
        fields.skype
      )
    },
    affiliateProfile: {
      companyName: maybe(
        trimmedLimitedLengthString(),
        'Enter a valid company name',
        fields.companyName
      ),
      website: maybe(
        extendedWebsiteWithPlaceholder,
        'Enter a valid website URL',
        fields.website
      ),
      address: maybe(address, 'Enter a valid address', fields.address),
      city: maybe(city, 'Enter a valid city', fields.city),
      countryId: object()
        .shape({
          label: string(),
          value: number()
        })
        .required('Select a country'),
      zipCode: maybe(zipCode, 'Enter a valid zip code', fields.zipCode),
      taxNumber: maybe(
        trimmedLimitedLengthString(),
        'Enter a valid tax number',
        fields.taxNumber
      )
    }
  }

  return toSchema(definitions)
}
