"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanSpacingRegEx = exports.linkedInUrlRegEx = exports.phoneNumberRegEx = exports.extendedWebsiteUrlRegEx = exports.zipCodeRegEx = exports.addressRegEx = exports.cityRegEx = exports.nameRegEx = void 0;
/**
 * Allows only letters, spaces, apostrophes ('), dots (.), and hyphens (-).
 * It also prevents consecutive spaces, special characters (e.g., "--", "''"), or start/ending with a special symbol.
 * A name can end on a dot (John Smith Jr.), but cannot start with one.
 */
exports.nameRegEx = /^(?!.*[.\-']{2,})(?!^\.)(?!.* \.$)(?!.* {2})(?!.*[.\-']\s[.\-'])(?!.*\s[.\-'][A-Za-zÀ-ÿ\s\p{L}])([A-Za-zÀ-ÿ\s\p{L}]([-'.]?[A-Za-zÀ-ÿ\s\p{L}]+|\.(?![A-Za-zÀ-ÿ\s\p{L}]))*)$/u;
/**
 * Allows letters, spaces, apostrophes ('), periods (.), and hyphens (-).
 * It also prevents consecutive special characters (e.g., "--", "''"), or start/ending with a special symbol.
 */
exports.cityRegEx = /^(?!.*[-'.]{2})[\p{L}\s'-.]+$/u;
/**
 * This regex validates addresses allowing letters, numbers, spaces, commas, periods, apostrophes ('), hyphens (-), hash symbols (#), and slashes (/).
 * It also prevents consecutive special characters (e.g., "--", "''"), or start/ending with a special symbol, besides starting with #, e.g. #23 Denver Street
 */
exports.addressRegEx = /^(?!.*[-'.]{2})(?!.*#$)[\p{L}0-9\s,.'-/#]+$/u;
/**
 * This regex allows zip codes containing letters, numbers, spaces, and hyphens (-), ensuring no zip code starts or ends with a hyphen.
 * It also prevents consecutive special characters (e.g., "--", "''"), or start/ending with a special symbol.
 */
exports.zipCodeRegEx = /^(?![-])(?:(?![-]{2})[A-Za-z0-9\s-])*(?![-])$/;
/**
 * The extendedWebsiteUrlRegEx accepts the following as valid:
 * - Protocols: https | http | NO PROTOCOL
 * - www. can be either added or ommited
 * - Valid Examples: https://google.com, http://www.google.com, www.google.com, google.com, google.co.uk
 */
exports.extendedWebsiteUrlRegEx = /^(?!.*\.\.)(https?:\/\/)?(www\.)?([a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,})(:\d{1,5})?(\/[^?#\s]*)?(\?[^#\s]*)?(#.*)?$/i;
/**
 * Allows only digits.
 */
exports.phoneNumberRegEx = /^\d+$/;
/**
 * Applies the extendedWebsiteUrlRegEx rules, but accepts only valid LinkedIn profile or company URLs:
 * Examples: https://linkedin.com/in/profile-name, www.linkedin.com/company/igsuite, linkedin.com/in/some-profile
 */
exports.linkedInUrlRegEx = /^(https?:\/\/)?(www\.)?linkedin\.com\/(company|in)\/[a-zA-Z0-9-]+$/;
/**
 * Disallows beginning with a space or using consecutive spaces.
 */
exports.cleanSpacingRegEx = /^(?!.* {2})(?!^\s)(?!.*\s$).*$/;
