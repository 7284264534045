import { InputCheckbox } from '@coddess-development/coddess-ui'
import { useField } from 'formik'

type OwnProps<T> = Partial<
  Pick<React.ComponentProps<typeof InputCheckbox>, 'onChange'>
> & {
  name: string
  transform?: (checked: boolean) => T
}

export type FormCheckboxProps<T> = OwnProps<T> &
  Omit<
    React.ComponentProps<typeof InputCheckbox>,
    'invalid' | keyof OwnProps<T>
  >

export const FormCheckbox = <T,>({
  name,
  transform = c => c as any,
  className,
  ...props
}: FormCheckboxProps<T>) => {
  const [{ value }, { error, touched }, { setTouched, setValue }] =
    useField(name)

  return (
    <div className={className}>
      <InputCheckbox
        {...props}
        defaultChecked={value}
        checked={value}
        invalid={touched && !!error}
        onChange={e => {
          // Formik is meant to be used with onChange and be passed a valid InputEvent
          // to automatically update the touched state otherwise. Thus we have to update
          // the touch state manually in order to display the error below.
          setTouched(true)

          setValue(transform(e.target.checked))
        }}
      />
      <div className="error">{touched && error}</div>
    </div>
  )
}
